<template>
  <div>
    <common-card>
      <v-card-text>
        <v-row style="padding-bottom: 15px; padding-top: 15px;">
          <v-col cols="8">
            <v-row>
              <v-col class="full-center-div" cols="3">
                <v-avatar color="primary" style="height: 100px; min-width: 100px; cursor: pointer;">
                  <span class="white--text text-h5">
                    {{
                      (
                        name.split(" ")[0][0] +
                        (name.split(" ")[1] ? name.split(" ")[1][0] : "")
                      ).toUpperCase()
                    }}
                  </span>
                </v-avatar>
              </v-col>
              <v-col cols="9" style="padding-left: 0;">
                <div class="text-h5 font-weight-bold" style="line-height: 6rem;">
                  {{ personalData.nombre + ' ' + personalData.apellidoPaterno + ' ' + personalData.apellidoMaterno }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical style="border-color: #00a7e4; border-width: 2px; height: 103px; margin-top: 9px; margin-right: 8px;"></v-divider>  <!-- Separamos un poco la línea azul del texto de la derecha -->
          <v-col cols="3">
            <div>
                <div class="text-body-1 mb-1"><span class="font-weight-bold">ID:</span> {{ personalData.id }}</div>
            </div>
            <div class="mt-3">
                <div class="text-body-1 mb-1"><span class="font-weight-bold">Ubicación:</span> {{ getStateName(personalData.nacimientoEstadoId) }}</div>
            </div>
            <div class="mt-3">
                <div class="text-body-1 mb-1"><span class="font-weight-bold">Estatus:</span> {{ getStatus(personalData.status) }}</div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </common-card>
  </div>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";

export default {
  components: {
    CommonCard,
  },
  props: {
    personalData: Object,
    stateList: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      name: localStorage.agenteName,
    };
  },
  methods: {
    getStateName(id) {
      if (this.stateList.length > 0) {
        const state = this.stateList.find(e => e.cEstado == id);
        return state ? state.dEstado : "No disponible";
      }
      else return "No disponible";
    },
    getStatus(status) {
      switch (status) {
        case -1:
          return "Baja";
        case 0:
          return "Nuevo";
        case 1:
          return "Activo";
        case 2:
          return "Inactivo";
        default:
          return "Nuevo";
      }
    },
  },
};
</script>

<style scoped>
.full-center-div {
  align-self: center;
  text-align: center;
}
</style>